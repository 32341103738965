<template>
    <div class="app-container">
        <div class="filter-container">
            <el-select v-model="listQuery.taskStatus" clearable :placeholder="$t('commons.status')"
                       style="width: 160px;" class="filter-item">
                <el-option
                        v-for="item in taskStatusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-input :placeholder="$t('userManager.userAccount')" maxlength="40" v-model="listQuery.userAccount" clearable
                      style="width: 150px;" class="filter-item" @keyup.enter.native="handleFilter"/>
            <el-select @change="handleFilter" v-model="listQuery.sourceApp" filterable clearable :placeholder="$t('commons.appName')">
                <el-option
                        v-for="item in appList"
                        :key="item.itemCode"
                        :value="item.itemCode"
                        :label="item.itemName">
                </el-option>
            </el-select>
            <el-input :placeholder="$t('commons.profileName')" maxlength="25" v-model="listQuery.profileName" clearable
                      style="width: 150px;" class="filter-item" @keyup.enter.native="handleFilter"/>
            <el-input :placeholder="$t('ops.asyncTaskTrace.taskUUID')" maxlength="50" v-model="listQuery.taskUUID" clearable
                      style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter"/>
            <el-date-picker
                    class="filter-item"
                    v-model="pickerOpts.pickerData"
                    type="datetimerange"
                    :picker-options="pickerOpts"
                    @change="setDateRange"
                    :range-separator="$t('commons.rangeSeparator')"
                    :start-placeholder="$t('commons.startDate')"
                    :end-placeholder="$t('commons.endDate')">
            </el-date-picker>
            <el-button class="filter-item" type="primary" icon="el-icon-search" :loading="listLoading" @click="handleFilter">{{
                $t('commons.search') }}
            </el-button>
        </div>
        <div class="filter-container">
            <el-switch  v-loading="openCloseLoading"
                        v-model="openClose"
                        active-value="open"
                        inactive-value="close"
                        active-color="#409EFF"
                        :active-text="$t('ops.asyncTaskTrace.openTrace')"
                        :inactive-text="$t('ops.asyncTaskTrace.closeTrace')"
                        @change="handleOpenCloseTrace">
            </el-switch>
        </div>
        <el-table
                v-loading="listLoading"
                :data="list"
                element-loading-text="Loading"
                @sort-change="handleSort"
                border
                fit
                highlight-current-row
                stripe>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="asynctask-table-expand">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$t('ops.asyncTaskTrace.brokerUrl')">
                                    <span>{{ scope.row.brokerUrl }}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('ops.asyncTaskTrace.queueName')">
                                    <span>{{ scope.row.queueName }}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$t('ops.asyncTaskTrace.messageType')">
                                    <span>{{ scope.row.messageType }}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('ops.asyncTaskTrace.consumerType')">
                                    <span>{{ scope.row.consumerType }}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$t('commons.startDate')">
                                    <template>
                                        <i class="el-icon-time"/>
                                        <span v-if="scope.row.startDate != null"> {{ scope.row.startDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                                    </template>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('commons.completeDate')">
                                    <template>
                                        <i class="el-icon-time"/>
                                        <span v-if="scope.row.completeDate != null"> {{ scope.row.completeDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                                    </template>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item v-if="scope.row.taskStatus === 2 || scope.row.taskStatus === 5"
                                              :label="$t('commons.errorMsg')">
                                    <pre class="asynctask-errorMsg-pre">{{scope.row.errorMsg}}</pre>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column prop="userAccount" :label="$t('userManager.userAccount')" width="200" align="center">
            </el-table-column>
            <el-table-column :label="$t('commons.status')" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.taskStatus | statusTagFilter">{{ scope.row.taskStatus | statusFilter }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="creationDate" sortable="custom" align="center" :label="$t('commons.creationDate')" width="250">
                <template slot-scope="scope">
                    <i class="el-icon-time"/>
                    <span> {{ scope.row.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="sourceApp" :label="$t('commons.appName')" width="200" align="center">
            </el-table-column>
            <el-table-column prop="profileName" :label="$t('commons.profileName')" width="200" align="center">
            </el-table-column>
            <el-table-column prop="taskUUID" :label="$t('ops.asyncTaskTrace.taskUUID')" align="center">
            </el-table-column>
            <el-table-column fixed="right" align="center" :label="$t('commons.actions')" width="150">
                <template slot-scope="scope">
                    <el-tooltip :content="$t('ops.asyncTaskTrace.showMsgContent')" placement="bottom"
                                :open-delay="openDelay">
                        <el-button size="mini"
                                   icon="el-icon-document"
                                   circle
                                   type="primary"
                                   @click="showMsgContent(scope.row)">
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.rowCount"
                    @pagination="getList"/>
        <!-- 显示消息内容对话框 -->
        <el-dialog :visible.sync="dialogVisible">
            <template slot="title">
                <title-icon/>{{ $t('ops.asyncTaskTrace.messageContent') }}
            </template>
            <pre v-loading="dialogLoading" class="asynctask-pre">{{messageContent}}</pre>
            <div slot="footer">
                <el-button type="primary" @click="closeMsgContent">{{$t('commons.close')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
    .asynctask-table-expand {
        font-size: 0;
    }

    .asynctask-table-expand label {
        color: #99a9bf;
    }

    .asynctask-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
    }

    .asynctask-pre {
        height: 50vh;
        overflow: auto;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        padding: 5px;
        margin: 0;
    }
    .asynctask-errorMsg-pre {
        white-space:pre-wrap;
        max-height: 50vh;
        overflow: auto;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        padding: 0px 10px;
    }
</style>

<script>
    import {getAsyncTaskTraceList, getMessageContent, setOpenCloseTrace, getOpenCloseTrace, findAppList} from '@/api/ruge/ops/ops'
    import Pagination from '@/components/Pagination'
    import i18n from '@/lang'
    import { buildSortStr } from '@/utils/global'

    export default {
        name: 'AsyncTaskTrace',
        components: {Pagination},
        filters: {
            // 返回状态tag效果
            statusTagFilter(status) {
                let str = '';
                if (status == 4) {
                    str = 'success';
                } else if (status == 2 || status == 5) {
                    str = 'danger';
                }
                return str;
            },
            // 返回国际化后的状态
            statusFilter(status) {
                return i18n.t('ops.asyncTaskTrace.taskStatus' + status);
            },
        },
        data() {
            let today = new Date();
            let todayTime = today.getTime();
            let yestodayTime = todayTime - 3600 * 1000 * 24;
            let opt = {
                appList:[],
                // Tooltip显示延迟时间(毫秒)
                openDelay: 1000,
                // 对话框是否可见，默认不可见
                dialogVisible: false,
                dialogLoading: false,
                // 要显示的消息内容
                messageContent: undefined,
                // 开启/关闭监控，close：关闭，open：开启
                openClose: 'close',
                openCloseLoading: false,
                list: null,
                total: 0,
                listLoading: true,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    // 排序条件,格式：column1:asc;column2:desc
                    sortStr: null,
                    userAccount: undefined,
                    sourceApp: undefined,
                    profileName: undefined,
                    taskUUID: undefined,
                    taskStatus: undefined,
                    startDate4Search: yestodayTime,
                    endDate4Search: todayTime,
                },
                // 日期范围查询组件
                pickerOpts: {
                    // 默认日期
                    pickerData: [new Date(yestodayTime), today],
                    // 快捷选项
                    shortcuts: undefined
                },
                // 状态下拉选项
                taskStatusOptions: [],
            }
            // 设置Picker快捷选项
            this.setPickerShortcuts(opt);
            // 设置状态下拉选项
            this.setTaskStatusOptions(opt);
            return opt
        },
        created() {
            this.getList();
            this.getTraceStatus();
            this.getAppList();
        },
        watch: {
            // 切换语言重新设置快捷选项和状态下拉选项
            language: function () {
                this.setPickerShortcuts();
                this.setTaskStatusOptions();
            }
        },
        computed: {
            language() {
                return this.$store.getters.language
            }
        },
        methods: {
            getAppList(){
                findAppList({classifyCode: 'APP_LIST'}).then((r)=> {
                    this.appList = r;
                })
            },
            getList() {
                this.listLoading = true
                getAsyncTaskTraceList(this.listQuery).then(response => {
                    this.list = response.rows;
                    this.total = response.total;
                    this.listLoading = false;
                })
            },
            // 处理动态排序
            handleSort(column) {
                this.listQuery.sortStr = buildSortStr(column);
                this.getList()
            },
            // 获取开启或关闭监控状态
            getTraceStatus() {
                this.openCloseLoading = true;
                getOpenCloseTrace().then(response => {
                    this.openClose = response;
                    this.openCloseLoading = false;
                }).catch(error => {
                    this.openCloseLoading = false;
                    console.log(`获取开启或关闭监控状态失败，原因 => ${error}`)
                })
            },
            // 查看消息内容
            showMsgContent(row) {
                this.dialogVisible = true;
                this.dialogLoading = true;
                getMessageContent(row).then(response => {
                    this.messageContent = response;
                    this.dialogLoading = false;
                })
            },
            //关闭模态框
            closeMsgContent(){
                this.dialogVisible = false;
            },
            // 处理开启/关闭监控
            handleOpenCloseTrace() {
                // 目标状态
                let targetStatus = this.openClose;
                // 未处理成功前，先保持现有状态
                this.openClose = this.openClose === 'close' ? 'open' : 'close';
                this.$confirm(this.$t('ops.asyncTaskTrace.setOpenCloseConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.openCloseLoading = true;
                    setOpenCloseTrace({"openClose":targetStatus}).then(() => {
                        this.openCloseLoading = false;
                        this.openClose = targetStatus;
                    }).catch(error => {
                        this.openCloseLoading = false;
                        console.log(`开启/关闭监控失败，原因 => ${error}`)
                    })
                }).catch(error => {
                    console.log(`未开启/关闭监控，原因 => ${error}`)
                });
            },
            handleFilter() {
                this.listQuery.current = 1
                this.getList()
            },
            // 设置日期范围查询条件
            setDateRange(dateRange) {
                if (dateRange) {
                    this.listQuery.startDate4Search = dateRange[0].getTime();
                    this.listQuery.endDate4Search = dateRange[1].getTime();
                } else {// 清空
                    this.listQuery.startDate4Search = null;
                    this.listQuery.endDate4Search = null;
                }
            },
            // 设置Picker快捷选项
            setPickerShortcuts(opt = this) {
                opt.pickerOpts.shortcuts = [{
                    text: this.$t('commons.lastDay'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('commons.lastWeek'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('commons.lastMonth'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 设置状态下拉选项
            setTaskStatusOptions(opt = this) {
                opt.taskStatusOptions = [{
                    value: 0,
                    label: this.$t('ops.asyncTaskTrace.taskStatus0')
                }, {
                    value: 1,
                    label: this.$t('ops.asyncTaskTrace.taskStatus1')
                }, {
                    value: 2,
                    label: this.$t('ops.asyncTaskTrace.taskStatus2')
                }, {
                    value: 3,
                    label: this.$t('ops.asyncTaskTrace.taskStatus3')
                }, {
                    value: 4,
                    label: this.$t('ops.asyncTaskTrace.taskStatus4')
                }, {
                    value: 5,
                    label: this.$t('ops.asyncTaskTrace.taskStatus5')
                }]
            },
        }
    }
</script>
