import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/************************** Redis监控 begin **********************************************/

/**
 * 查询Redis列表
 */
export function getRedisList() {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/redis/getList`,
    method: 'get',
  })
}

/**
 * 查询Reids Key的值
 * @param params
 */
export function getRedisValue(params) {
  return request({
    url: envInfo.bgApp.opsPath + `/ops/redis/getValue?pathKey=${params.pathKey}`,
    method: 'get',
  })
}

/**
 * 修改Redis对应Key的值(string)
 * @param params
 */
export function updateRedisValue(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/redis/update`,
        method: 'post',
        data: params
    })
}

/**
 * 修改Redis对应Key中的HashKey的值
 * @param params
 */
export function updateRedisHashValue(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/redis/updateHash`,
        method: 'post',
        data: params
    })
}

/**
 * 删除对应Reids的Key
 * @param params
 */
export function deleteRedisKey(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/redis/delete?pathKey=${params.pathKey}`,
        method: 'delete',
    })
}

/**
 * 删除Redis对应Key中的HashKey
 * @param params
 */
export function deleteRedisHashKey(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/redis/deleteHash`,
        method: 'post',
        data: params
    })
}

/************************** Redis监控 end **********************************************/

/************************** 异步任务监控 begin **********************************************/

/**
 * 查询异步任务执行记录列表
 * @param params
 */
export function getAsyncTaskTraceList(params) {
    return request({
        url: envInfo.bgApp.opsPath + '/ops/asyncTaskTrace/list',
        method: 'get',
        params
    })
}

/**
 * 获取任务消息内容
 * @param params
 */
export function getMessageContent(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/asyncTaskTrace/findMessageContent/${params.taskUUID}`,
        method: 'get',
    })
}

/**
 * 处理开启/关闭监控
 * @param isOpen
 */
export function setOpenCloseTrace(params) {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/asyncTaskTrace/set`,
        method: 'post',
        data : params
    })
}

/**
 * 获取任务消息内容
 * @param params
 */
/**
 *
 */
export function getOpenCloseTrace() {
    return request({
        url: envInfo.bgApp.opsPath + `/ops/asyncTaskTrace/findOpenClose`,
        method: 'get',
    })
}

/************************** 异步任务监控 end **********************************************/

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}
